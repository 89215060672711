<template>
	<div class="page-content">
		<div class="park-form">
			<el-form inline label-position="top" ref="searchForms" style="display: flex;flex-wrap: wrap">
				<!--<el-form-item label="客户电话" prop="username">-->
				<!--<el-input v-model="searchForm.name" style="width: 220px;" clearable-->
				<!--placeholder="请填写电话"></el-input>-->
				<!--</el-form-item>-->
				<el-form-item label="主题" prop="themes_id">
					<el-select v-model="searchForm.themes_id" clearable placeholder="请选择主题">
						<el-option v-for="item in themeList" :key="item.id" :label="item.title" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item style="margin-top: auto;margin-right: auto;">
					<div class="fill-btn" @click="reserveList">查询</div>
				</el-form-item>
				<el-form-item style="margin-top: auto;margin-left: 20px;margin-right: 0;">
					<div class="fill-btn" @click="handShowAdd">新增</div>
				</el-form-item>

			</el-form>
		</div>
		<div class="table-content">
			<div class="table-top">
				<div class="table-top-text">
					<!--已选择{{selectData.length}}项-->
				</div>
			</div>
			<el-table :data="tableData" stripe v-loading="loading" @selection-change="handleSelectionChange"
				cell-class-name="text-center" header-cell-class-name="header-row-class-name">
				<el-table-column prop="title" label="主题名称" min-width="120px"></el-table-column>
				<el-table-column prop="images" label="主题封面" min-width="50%">
					<!-- 图片的显示 -->
					<template slot-scope="scope">
						<img :src="scope.row.images" min-width="80" height="70" style="width: 100%" />
					</template>
				</el-table-column>


				<el-table-column prop="start_time" label="开始时间" min-width="120px"></el-table-column>
				<el-table-column prop="end_time" min-width="160px" label="结束时间"></el-table-column>
				<!--<el-table-column prop="images" label="已满状态" min-width="100%">-->
				<!--<template slot-scope="scope">-->
				<!--<el-switch-->
				<!--v-model="scope.row.status"-->
				<!--active-color="#13ce66"-->
				<!--inactive-color="#ff4949"-->
				<!--active-value="1"-->
				<!--inactive-value="2"-->
				<!--active-text="正常"-->
				<!--inactive-text="已满"-->
				<!--change=""-->
				<!--&gt;-->
				<!--</el-switch>-->
				<!--</template>-->
				<!--</el-table-column>-->
				<el-table-column width="200" label="操作" fixed="right">
					<template slot-scope="{row}">
						<div class="btn-content">
							<el-button class="el-icon-edit-outline cur-p" type="primary" size="mini"
								@click="handEditShow(row)"></el-button>
							<el-button class="el-icon-delete cur-p" type="primary" size="mini" v-if="row.status != 2"
								@click="delType(row)"></el-button>

						</div>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination-content">
				<el-pagination background :current-page.sync="searchForm.page" :page-size="searchForm.limit"
					@current-change="searchForm.page=$event" @size-change="handleSizeChange"
					layout="total, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>

		<el-dialog title="新增设置" :visible.sync="showAdd">
			<div style="padding: 20px;">
				<el-form ref="forms" :model="addTeacher" label-width="80px">
					<el-form-item label="主题" prop="themes_id">
						<el-select v-model="addTeacher.themes_id" multiple placeholder="请选择">
							<el-option v-for="item in themeList" :key="item.id" :label="item.title" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="工作时间">
						<el-time-picker is-range v-model="workTime" format="HH:mm" value-format="HH:mm"
							range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围">
						</el-time-picker>
					</el-form-item>

					<el-form-item label="时间区间" prop="time">
						<div style="display: flex;justify-content: space-between;">
							<el-input v-model="addTeacher.time" type="number">
								<template slot="append">分钟</template>
							</el-input>
							<div class="ml-10" style="width: 80px;"></div>
						</div>
					</el-form-item>
					<el-form-item label="区间间隔" prop="interval">
						<div style="display: flex;justify-content: space-between;">
							<el-input v-model="addTeacher.interval" type="number">
								<template slot="append">分钟</template>
							</el-input>
							<el-button class="ml-10" type="primary" plain @click="makeSure">生成</el-button>
						</div>
					</el-form-item>
					<el-form-item label="时间" v-for="(val,key) in addTeacher.price_time" :key="key">
						<el-time-picker is-range v-model="val.value" format="HH:mm" value-format="HH:mm"
							range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围">
						</el-time-picker>
						<el-button class="ml-10" @click="delDomain(key)" icon="el-icon-minus"></el-button>
					</el-form-item>
					<el-form-item>
						<el-button @click="addDomain" icon="el-icon-plus"></el-button>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="addType" style="margin: 10px auto">保存</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
		<el-dialog title="编辑" :visible.sync="showEdit">
			<div style="padding: 20px;">
				<el-form ref="form" :model="editForm" label-width="80px">
					<el-form-item label="日期选择">
						<el-time-picker is-range v-model="editForm.price_time" format="HH:mm" value-format="HH:mm"
							range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围">
						</el-time-picker>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" :loading="editing" @click="saveType" style="margin: 10px auto">保存
						</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import {
		delToast,
		saveToast,
		formVerify,
		ascertain
	} from './../../utlis/decorators'
	import RichText from './../../components/RichText'

	export default {
		name: "tenant_account",
		props: {
			parkId: {
				type: [Number, String],
				default: null
			},
		},
		components: {
			RichText
		},
		data() {
			return {
				dialogFormVisible: false,
				loading: false,
				currentTop: 0,
				total: 0,
				showAdd: false,
				showEdit: false,
				showEditPsd: false,
				editing: false,
				orderexcel: false,
				themeList: [],
				searchForm: {
					page: 1,
					limit: 10,
					themes_id: ''
				},
				form: {
					nickname: '',
					is_limit: 2
				},
				editForm: {
					id: '',
					price_time: [],
				},
				excelorder: {},
				currentId: '',
				tableData: [],
				typeList: [],
				product_id: '',
				addTeacher: {
					price_time: [],
					themes_id: [],
					interval: '',
					time: '',
				},

				ratio: [{
					value: '1',
					label: '百分比'
				}, {
					value: '2',
					label: '固定金额'
				}],
				all: [],
				file: null,

				workTime: [],
				workTimes: ['00:00:00','23:59:59'],
			}
		},
		created() {
			this.reserveList();
			this.getThemeLists();
			this.getStoreTimeInfo();
		},
		computed: {
			getRules() {
				return {
					role_ids: [{
						required: true,
						message: `角色不能为空`,
						trigger: ['blur', 'change']
					}],
					nickname: [{
						required: true,
						message: `昵称不能为空`,
						trigger: ['blur', 'change']
					}],

				}
			}
		},
		watch: {
			'searchForm.page'() {
				this.reserveList();
			},

		},

		methods: {
			timeSlot(workTime, step, length) { //length = 时间长度 //  step = 间隔的分钟
				//获取前一天下午四点的时间；后面距离24点n小时，则减去1000*60*60*n. 
				//比如：如果要获取前一天下午八点，（24-20= 4）后面减去1000*60*60*4
				// var date = new Date(new Date(new Date().toLocaleDateString()).getTime() - 1000 * 60 * 60 * 8);
				let start = '',end = '';
				if (workTime.length == 2) {
					start = ' ' + workTime[0] + ':00';
					end = ' ' + workTime[1] + ':00';
				}
				var date = new Date(new Date(new Date().toLocaleDateString() + start).getTime());
				var date1 = new Date(new Date(new Date().toLocaleDateString() + end).getTime());
				var arr = [],
					timeArr = [];
				//此处24*60/step，获取的24个小时内有多少间隔。 将24改为12，则可获取12小时内的间隔
				var slotNum = Math.floor(parseInt(Math.abs(date1-date)/1000/60 + Number(length)) / (Number(step) + Number(length))) // 算出多少个间隔
				for (var f = 0; f < slotNum; f++) { //  stepM * f = 24H*60M
					// 获取：零点的时间 + 每次递增的时间
					var startTime = new Date(Number(date.getTime()) + Number((Number(step) + Number(length)) * 60 * 1000 *
						f))
					var endTime = new Date(Number(startTime.getTime() + Number(step * 60 * 1000)))

					var hour = '',
						sec = '',
						hour1 = '',
						sec1 = '';
					// 获取小时
					startTime.getHours() < 10 ? hour = '0' + startTime.getHours() : hour = startTime.getHours()
					// 获取分钟
					startTime.getMinutes() < 10 ? sec = '0' + startTime.getMinutes() : sec = startTime.getMinutes()

					// 获取小时
					endTime.getHours() < 10 ? hour1 = '0' + endTime.getHours() : hour1 = endTime.getHours()
					// 获取分钟
					endTime.getMinutes() < 10 ? sec1 = '0' + endTime.getMinutes() : sec1 = endTime.getMinutes()


					timeArr.push({
						value: [hour + ':' + sec, hour1 + ':' + sec1]
					})
				}
				return timeArr
			},
			makeSure() {
				if (this.addTeacher.interval != '' && this.addTeacher.time != '') {
					this.addTeacher.price_time = this.timeSlot(this.workTime, this.addTeacher.time, this.addTeacher
						.interval)
				}
			},
			addDomain() {
				this.addTeacher.price_time = [...this.addTeacher.price_time, {}]
				/*this.$set(this.addTeacher.price_time,'value',[])*/
				// this.addTeacher.price_time.push({
				//     value:[new Date(2016, 9, 10, 8, 40)]
				// });

			},
			delDomain(key) {
				console.log(key);
				this.addTeacher.price_time.splice(key, 1);

			},
			//添加
			async addType() {
				this.loading = true;
				let [e, data] = await this.$api.reserveCreate(this.addTeacher);
				this.loading = false;
				if (e) return;

				if (data.code === 200) {
					// this.getTeacherList();
					this.loading = false;
					this.showAdd = false;
					this.reserveList();
					this.$message.success('添加成功')
				} else {

					this.$message.warning(`${data.msg}`)

				}
			},
			handShowAdd() {
				this.workTime = this.workTimes;
				try {
					this.showAdd = true;
					this.$refs['forms'].resetFields()
					this.addTeacher.price_time = [];
					this.addTeacher.themes_id = [];
					this.addTeacher.interval = '';
					this.addTeacher.time = '';				
				} catch {}
			},
			//获取店铺上班时间
			async getStoreTimeInfo() {
				let [e, data] = await this.$api.getStoreTimeInfoApi();
				if (e) return;
				if (data.code === 200) {
					let time = data.data;

					this.workTimes = [time.start_time, time.end_time]
				}
			},
			//获取主题
			async getThemeLists() {
				let [e, data] = await this.$api.storeThemeList();
				if (e) return;

				if (data.code == 200) {
					this.themeList = data.data;

				}
			},
			handEditShow(row) {
				this.editForm.id = row.id;
				// this.workTime = this.workTimes;
				this.editForm.price_time = [row.start_time, row.end_time];
				this.showEdit = true;
			},
			//页面数量改变
			handleSizeChange(val) {
				this.searchForm.page = 1;
				this.searchForm.limit = val;
			},
			handleSelectionChange(val) {
				this.selectData = val;
			},
			//获取列表
			async reserveList() {
				let [e, data] = await this.$api.reserveList(this.searchForm);
				if (e) return;
				if (data.code === 200) {
					this.tableData = data.data.data;
					this.total = data.data.total;
				}
			},


			@delToast()
			async delType({
				id
			}) {
				let [e, data] = await this.$api.reserveDel({
					id
				});
				if (e) return;
				if (data.code === 200) {
					this.reserveList();
					this.$message.success('删除成功')
				} else {
					this.$message.warning(`${data.msg}`)
				}
			},
			//预约设置编辑
			async saveType() {
				this.editing = true;
				let [e, data] = await this.$api.reserveSave({
					id: this.editForm.id,
					start_time: this.editForm.price_time.length == 2 && this.editForm.price_time[0] || '',
					end_time: this.editForm.price_time.length == 2 && this.editForm.price_time[1] || '',
				});
				this.editing = false;
				if (e) return;
				if (data.code === 200) {
					this.showEdit = false;
					this.reserveList();
					this.$message.success('保存成功')
				} else {
					this.$message.warning(`${data.msg}`)
				}
			}
		},
	}
</script>
<style scoped lang="less">
	.page-content {
		width: calc(~'100% - 60px');
		margin: 26px 30px;
		box-sizing: border-box;
		background-color: #fff;
		border-radius: 4px;
		padding: 20px 30px;

		.park-form {
			border-bottom: 1px solid #efefef;
		}

		.table-content {
			padding-top: 20px;

			.table-top {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.table-top-text {
					font-size: 16px;
					font-weight: 400;
					color: rgba(35, 39, 52, 1);
				}

				.table-top-btn {}
			}
		}
	}

	.btn-content {
		display: flex;
		justify-content: center;
		align-items: center;

		.iconfont {
			font-size: 20px;
			color: rgb(190, 199, 213);
			margin: 0 10px;
			cursor: pointer;
		}

		.icon-shanchu {
			font-size: 24px;
		}
	}

	.box-shadow {
		width: calc(~'100% + 40px');
		height: 50px;
		position: absolute;
		top: 0;
		left: -20px;
		z-index: 10;
		box-shadow: 0 0 4px #b4b4b4;
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>
